import {AfterViewInit, Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {invsy} from '@investec/invsy-sdk';
import {BankerDetails} from '../../models/banker-details';
import {takeUntil} from 'rxjs/operators';
import {ForYouService} from '../../services/for-you.service';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {BehaviorSubject, Observable, Subject, Subscription} from 'rxjs';
import {IClientDetails} from '../../models/client-details.model';
import {Router} from "@angular/router";

@Component({
  selector: 'app-private-banker-request-callback',
  templateUrl: './private-banker-request-callback.component.html',
  styleUrls: ['./private-banker-request-callback.component.scss'],
})
export class PrivateBankerRequestCallbackComponent implements OnInit, AfterViewInit {
  private unsubscribe$ = new Subject();
  isCallBack = false;
  selectedProfileId? = '';
  bankerDetails = {} as BankerDetails;
  isloading = false;
  isFailed = false;
  isLesFailed = false;
  isLesLoading = false;
  clientDetails = {} as IClientDetails;
  oneViewId: BehaviorSubject<string>;
  selectedProfile: any;
  authResponse: any;
  userSettings: any;
  portfolioData: any;

  @ViewChild('modal') modal = {} as TemplateRef<any>;
  @ViewChild('modalSuccess') modalSuccess = {} as TemplateRef<any>;
  @ViewChild('modalError') modalError = {} as TemplateRef<any>;
  @ViewChild('modalFail') modalFail = {} as TemplateRef<any>;

  @Input() openCallbackModal: boolean = false;

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private forYouService: ForYouService,
    public activeModal: NgbActiveModal,
  ) {
    this.oneViewId = new BehaviorSubject<string>('init');
    this.forYouService._selectedProfile.subscribe(res => {
      this.selectedProfile = res
    })
    this.forYouService._authResponse.subscribe(res => {
      this.authResponse = res
    })
    this.forYouService._userSetting.subscribe(res => {
      this.userSettings = res
    })
    this.forYouService._portfolio.subscribe(res => {
      this.portfolioData = res
    })
  }

  ngOnInit(): void {
    this.isloading = true;
    this.isCallBack = false;
    this.isFailed = false;
    this.getOneViewId();
    this.getClientDetails();
  }

  ngAfterViewInit() {
    this.openModal();
  }
  openModal(){
    if (this.openCallbackModal) {
      this.modalService.open(this.modal, {size: 'xl', backdrop: 'static', scrollable: false, keyboard: false});
    }
  }

  getOneViewId() {
    this.forYouService.getOneViewId().subscribe(
      (response) => {
        this.setValue(response?.data?.entity?.identifiers?.aliasSummary?.oneViewId);
      },
      (error: any) => {
        this.setValue('');
      }
    );
  }

  getClientDetails() {
    this.forYouService.getClientDetails().subscribe(
      (clientDetails: IClientDetails) => {
        this.clientDetails = clientDetails;
        this.getSelectedProfile();
      },
      (error: any) => {
        this.isloading = false;
        this.isFailed = true;
      }
    );
  }

  getSelectedProfile(): void {
    if (this.selectedProfile?.ProfileId) {
      this.selectedProfileId = this.selectedProfile.ProfileId;
      this.getBankerDetails();
    } else {
      invsy.data.getSelectedProfile().pipe(takeUntil(this.unsubscribe$)).subscribe(
        (profile) => {
          if (Object.keys(profile.result).length > 0) {
            this.selectedProfileId = profile?.result?.ProfileId;
            this.getBankerDetails();
          }
        },
        (error: any) => {
          this.isloading = false;
          this.isFailed = true;
        }
      );
    }
  }

  getBankerDetails(): void {
    if (this.portfolioData) {
      this.bankerDetails = this.portfolioData.contactData.privateBanker;
      this.isloading = false;
      this.isFailed = false;
    } else {
      invsy.data.portfolio.getPrivateBankZAPortfolio(this.userSettings.Currency, this.selectedProfileId).then(
        (portfolio) => {
          this.bankerDetails = portfolio?.result['PrivateBanker'];
          this.isloading = false;
          this.isFailed = false;
        },
        (error: any) => {
          this.isloading = false;
          this.isFailed = true;
        }
      );
    }
  }

  getValue(): Observable<string> {
    return this.oneViewId.asObservable();
  }

  setValue(value: string): void {
    this.oneViewId.next(value);
  }

  callBack(): void {
    this.isLesLoading = true;
    this.getValue().subscribe((value) => {
      if (value !== 'init') {
        const payload = {
          source: {
            id: new Date().getTime().toString(),
            channelId: 'Investec Online',
            pageUrl: window.document.URL,
          },
          lead: {
            firstName: this.clientDetails?.FirstName,
            lastName: this.clientDetails?.LastName,
            email: this.clientDetails?.EmailAddress,
            contactNumber: this.clientDetails?.MobileNumber,
            campaignName: 'Investec Online - Investe & Save',
            additionalFields: {
              ProductName: 'Invest and Save',
              BankerName: this.bankerDetails.DisplayName,
              BankerEmail: this.bankerDetails.Email,
              oneViewId: value,
            },
          },
          destination: {
            businessUnitId: '1006',
            jurisdictionId: 'ZAF',
            email: '',
          },
          id: '',
        };
        this.forYouService.requestCallBack(payload).subscribe(
          (response) => {
            this.isLesLoading = false;
            this.isCallBack = true;
            this.isLesFailed = false;
            this.modalService.dismissAll(this.modal);
            this.modalService.open(this.modalSuccess, {
              size: 'xl',
              backdrop: 'static',
              scrollable: false,
              keyboard: false
            });
          },
          (error: any) => {
            this.isLesLoading = false;
            this.isLesFailed = true;
            this.modalService.dismissAll(this.modal);
            this.modalService.open(this.modalFail, {size: 'xl', backdrop: 'static', scrollable: false, keyboard: false});
          }
        );
      }
    });
  }

  refreshed(): void {
    this.getSelectedProfile();
    this.getOneViewId();
  }

  refreshLes(): void {
    this.isCallBack = false;
  }

  returnToEntranceHall() {
    this.modalService.dismissAll(this.modal);
    this.modalService.dismissAll(this.modalSuccess);
    this.modalService.dismissAll(this.modalError);
    this.modalService.dismissAll(this.modalFail);
    this.router.navigate(['entrance-hall']);
  }

}
