<div class="row">
  <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4 pb-3" *ngFor="let product of badgesArray">
    <div class="card h-100 mb-2 pl-1 pr-1 pb-1">
      <div class="card-body">
        <div class="m-2 float-top-right">
          <ui-badge *ngIf="product.badges?.primaryBadges?.isCore" class="mr-1" [size]="'medium-badge'" [text]="'Core'"
                    [badgeType]="'primary'"></ui-badge>
          <ui-badge *ngIf="product.badges?.primaryBadges?.isInvest" [size]="'medium-badge'" [text]="'Invest'"
                    [badgeType]="'okay'"></ui-badge>
          <ui-badge *ngIf="product.badges?.primaryBadges?.isSave" [size]="'medium-badge'" [text]="'Save'"
                    [badgeType]="'okay'"></ui-badge>
        </div>
        <div class="card-title mb-2 pt-5" style="height:160px;">
          <p class="display-5">{{product.listedName}}</p>
        </div>
        <div class="mt-2">
          <div *ngIf="showDescription" class="card-text" style="height:150px;">
            <p class="overline-2">ASSET CLASS</p>
            <p class=" m-0 p-0">{{product.productDescription}}</p>
          </div>
        </div>
        <div class="row-responsive">
          <ui-badge *ngFor="let badge of product.badges?.secondaryBadges" class="col-3 pl-0 pr-1 pb-1" [size]="'small'"
                    [text]="badge"
                    [badgeType]="'good'"></ui-badge>
        </div>
        <div class="pb-1 row-responsive">
          <ui-badge *ngFor="let badge of product.badges?.tertiaryBadges" class="col-3 pl-0 pr-1 pb-1" [size]="'small'"
                    [text]="badge"
                    [badgeType]="badge === 'Offshore' ? 'risk' : 'okay'"></ui-badge>
        </div>
        <div class="pt-3">
          <ui-button [btnClass]="'link'" [block]="false" [size]="'md'"
                     (click)="navigateToProductMoreDetails(product.primaryButtonUrl)">
            More Details
          </ui-button>
          <ui-button [btnClass]="'secondary'" [block]="false" [size]="'md'"
                     (click)="navigateToProductOnboarding(product.secondaryButtonUrl)">
            Invest Now
          </ui-button>
        </div>
      </div>
    </div>
  </div>
</div>
