import { Injectable } from '@angular/core';
import { NavigationEnd, Router} from '@angular/router';
import { environment } from '../../../../../environments/environment';
import { invsy} from "@investec/invsy-sdk";

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  authentication = environment.platform.authentication;

  constructor(private _router: Router) {}

  setUrlOnRouteChange(){
    this._router.events.subscribe(res => {
      if (res instanceof NavigationEnd) {
        invsy.platform.updateUrl( res.url);
      }
    });
  }
}
