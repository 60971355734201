import {Component, forwardRef, OnChanges, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {ProductsService} from "../services/products/products.service";
import {Product} from "../models/Product.interface";


@Component({
  selector: 'app-explore-products',
  templateUrl: './explore-products.component.html',
  styleUrls: ['./explore-products.component.scss'],
  providers: [{provide: ActivatedRoute, useExisting: forwardRef(() => ExploreProductsComponent)}]
})
export class ExploreProductsComponent {

  products: Product[] = []
  filteredProducts: Product[] = []
  numberOfProducts = 0;
  selectedOptions: string[] = [];
  searchTerm: string = "";
  searchResults: Product[] = [];
  advancedFilter = {name: "",filterSelection:[{ id: 0, name: '' }]};
  tabFilteredProducts: Product[]= [];
  noProducts = false;


  tabs = [
    {
      name: 'Savings Products',
      id: 0
    }, {
      name: 'Retirement Annuities',
      id: 1
    }, {
      name: 'Tax Free Investments',
      id: 2
    }, {
      name: 'Local Unit Trusts',
      id: 3
    }, {
      name: 'Offshore Unit Trusts',
      id: 4
    },
  ]

  AdvancedFilter = [
    {
      name: 'Savings Products',
      filterSelection: [
        {
          id: 0,
          name: 'All',
        }, {
          id: 1,
          name: 'Instant Access',
        }, {
          id: 2,
          name: 'Notice Deposit',
        }, {
          id: 3,
          name: 'Term Deposit',
        }]
    }, {
      name: 'Retirement Annuities',
      filterSelection: [
        {
          id: 0,
          name: 'All',
        }, {
          id: 1,
          name: 'Greater Certainty',
        }, {
          id: 2,
          name: 'Moderate Certainty',
        }, {
          id: 3,
          name: 'Less Certainty',
        }]
    }, {
      name: 'Tax Free Investments',
      filterSelection: [
        {
          id: 0,
          name: 'All',
        }, {
          id: 1,
          name: 'Greater Certainty',
        }, {
          id: 2,
          name: 'Moderate Certainty',
        }, {
          id: 3,
          name: 'Less Certainty',
        }, {
          id: 4,
          name: 'Local',
        }, {
          id: 5,
          name: 'Offshore',
        }]
    }, {
      name: 'Local Unit Trusts',
      filterSelection: [
        {
          id: 0,
          name: 'All',
        }, {
          id: 1,
          name: 'Greater Certainty',
        }, {
          id: 2,
          name: 'Moderate Certainty',
        }, {
          id: 3,
          name: 'Less Certainty',
        }]
    }, {
      name: 'Offshore Unit Trusts',
      filterSelection: [
        {
          id: 0,
          name: 'All',
        }, {
          id: 1,
          name: 'Greater Certainty',
        }, {
          id: 2,
          name: 'Moderate Certainty',
        }, {
          id: 3,
          name: 'Less Certainty',
        }]
    },
  ]


  constructor(private router: Router,
              public _productService: ProductsService) {
    _productService.getProducts().subscribe(products => {
      this.initialiseProducts(products);
    });
  }

  initialiseProducts(products: Product[]) {
    this.products = products;
    this.numberOfProducts = this.products.length;
    this.selectedOptions = [];
    this.selectTab('Savings Products', 0)
  }


  selectTab(tabName: string, id: number) {
    this.filteredProducts = [...this.products.filter((product) => {
      return (product.productType!.indexOf(tabName) >= 0);
    })];
    this.searchResults = [...this.filteredProducts]
    this.tabFilteredProducts = [...this.filteredProducts]
    const temList = this.AdvancedFilter.find(item =>
      item.name === tabName
    )

    if(temList !== undefined){
      this.advancedFilter = temList
    }
  }

  navigateToEntranceHall() {
    this.router.navigate(['entrance-hall']);
  }

  navigateToContactBanker() {
    this.router.navigate(['contact-banker']);
  }

  searchProducts() {
    const searchTerm = this.searchTerm.toLowerCase();
    if (searchTerm.length > 0) {
      this.filteredProducts = [...this.filteredProducts.filter(product =>
        product.listedName.toLowerCase().includes(searchTerm))];
    } else {
      this.filteredProducts = [...this.searchResults]
    }
  }

  selectAdvancedFilter(filterName: string, id: number) {
    if (filterName != 'All') {
      this.noProducts = false;
      this.filteredProducts = [...this.tabFilteredProducts.filter((product) => {
        // @ts-ignore
        return (product.badges!.tertiaryBadges!.includes(filterName) || product.badges!.secondaryBadges!.includes(filterName));
      })];

      if(this.filteredProducts.length <= 0){
        this.noProducts = true;
      }

    } else {
      this.filteredProducts = [...this.searchResults]
    }
  }

}
