import {Component, TemplateRef, ViewChild} from '@angular/core';
import {QuestionsService} from '../services/questions/questions.service';
import {Question, QuestionClass} from '../models/Question.interface';
import {BankingCard} from '@investec/io-bootstrap/cards/banking-card';
import {Router} from '@angular/router';
import {Attributes} from '../models/Attributes';
import {FilterService} from '../services/filter/filter.service';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-help-select-product',
  templateUrl: './help-select-product.component.html',
  styleUrls: ['./help-select-product.component.scss']
})
export class HelpSelectProductComponent {

  private questions: Question[] = [];
  public numberOfQuestions: number = 0;
  public currentQuestionNumber: number = 0;
  public currentQuestion: Question = new QuestionClass;
  public optionList: {
    "option": string,
    "attributes": Attributes,
    "isSelected": boolean
  }[] = [];
  public selectedOption: string = "";
  public cards: BankingCard[] = [];
  public inputAmount: string = "";
  private questionAnswers: {
    "option": string | number,
    "attributes": Attributes
  }[] = [];

  @ViewChild('modalExit') modalExit  = {} as TemplateRef<any>;

  constructor(questionService: QuestionsService,
              private filterService: FilterService,
              private router: Router,
              private modalService: NgbModal,
  ) {
    questionService.getQuestions().subscribe(questions => {
      this.initialiseQuestions(questions);
    });
  }

  initialiseQuestions(questions: Question[]) {
    this.questions = questions;
    this.numberOfQuestions = this.questions.length;
    this.currentQuestionNumber = 0;
    this.setQuestion();
    this.optionList = [];
  }

  setQuestion() {
    this.currentQuestion = this.questions[this.currentQuestionNumber];
    if (this.currentQuestion.inputType == "card-select") {
      this.populateCards();
    } else if (this.currentQuestion.inputType == "multi-select") {
      this.populateSelectedOptions();
    } else if (this.currentQuestion.inputType == "amount") {
      this.populateAmount();
    }
  }

  nextQuestion() {
    this.currentQuestionNumber++;
    if (this.currentQuestionNumber < this.numberOfQuestions) {
      this.setQuestion();
    } else {
      this.navigateToRecommendedProducts();
    }
  }

  previousQuestion() {
    this.currentQuestionNumber--;
    if (this.currentQuestionNumber >= 0) {
      this.setQuestion();
    } else {
      this.navigateToEntranceHall();
    }
  }

  saveOption(option: string) {
    if (this.currentQuestion.inputOptions) {
      this.currentQuestion.inputOptions.forEach(inputOption => {
        if (option == inputOption.option) {
          const questionAnswer = {
            option: option,
            attributes: inputOption.attributes
          }
          this.saveAnswer(questionAnswer);
        }
      });
    }
  }

  saveAmount(amount: number) {
    const questionAnswer = {
      option: amount,
      attributes: []
    }
    this.saveAnswer(questionAnswer);
  }

  saveAnswer(questionAnswer: { option: string | number, attributes: Attributes }) {
    if (this.currentQuestionNumber > this.questionAnswers.length) {
      this.questionAnswers.push(questionAnswer);
    } else {
      this.questionAnswers[this.currentQuestionNumber] = questionAnswer;
    }
  }

  populateCards() {
    this.cards = [];
    if (this.currentQuestion.inputOptions && this.currentQuestion.inputOptions[0].cardOption) {
      this.currentQuestion.inputOptions.forEach(inputOption => {
        if (inputOption.cardOption) {
          this.cards.push(inputOption.cardOption);
        }
      });
    }
  }

  populateSelectedOptions() {
    this.selectedOption = "";
    if (this.currentQuestion.inputOptions) {
      this.optionList = [];
      if (this.currentQuestionNumber < this.questionAnswers.length) {
        this.selectedOption = this.questionAnswers[this.currentQuestionNumber].option + "";
      }
      this.currentQuestion.inputOptions.forEach(inputOption => {
        this.optionList.push(
          {
            option: inputOption.option,
            attributes: inputOption.attributes,
            isSelected: this.selectedOption == inputOption.option
          }
        )
      });
    }
  }

  populateAmount() {
    if (this.currentQuestionNumber < this.questionAnswers.length) {
      var amount = this.questionAnswers[this.currentQuestionNumber].option + "";
      this.inputAmount = this.formatNumberSpacing(amount);
    } else {
      this.inputAmount = "";
    }
  }

  changeAmount(event: Event) {
    var amount = parseInt((event.target as HTMLInputElement).value)
    this.saveAmount(amount);
  }

  clickOption(clickedOption: string) {
    this.selectedOption = "";
    this.optionList.forEach(option => {
      if (clickedOption == option.option && !option.isSelected) {
        option.isSelected = true;
        this.selectedOption = clickedOption;
      } else {
        option.isSelected = false;
      }
    });
    this.saveOption(clickedOption);
  }

  clickContinue() {
    if (this.currentQuestion.inputType == "card-select" || this.currentQuestion.inputType == "multi-select") {
      this.saveOption(this.selectedOption);
    } else {
      console.log("Click Save:", this.inputAmount)
      // this.saveAmount(parseInt(this.inputAmount));
    }
    this.nextQuestion();
  }

  clickBack() {
    this.previousQuestion();
  }

  clickCard(card: BankingCard) {
    this.saveOption(card.header);
    this.nextQuestion();
  }

  navigateToEntranceHall() {
    this.router.navigate(['entrance-hall']);
  }

  navigateToRecommendedProducts() {
    this.filterService.setQuestionAnswers(this.questionAnswers);
    this.router.navigate(['recommended-products']);
  }

  navigateToContactBanker() {
    this.router.navigate(['contact-banker']);
  }

  formatAmount(value: string): string {
    var numericValue = value.replace(/[^0-9]/g, '') ?? '';
    return numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  showPopup() {
    const confirmationMessage = 'Your answers have not been saved. If you leave now, all answers will be lost. Are you certain you want to discard these answers?';
    if (confirm(confirmationMessage)) {
      this.navigateToEntranceHall();
    }
  }

  openExitModal() {
    this.modalService.open(this.modalExit, {
      size: 'm',
      backdrop: 'static',
      scrollable: false,
      keyboard: false,
      centered: true
    });
  }

  closeModal() {
    this.modalService.dismissAll(this.modalExit);
  }

  preventNonNumericInput(event: KeyboardEvent) {
    const keyValue = event.key;
    const numericRegex = /^[0-9]*$/;

    if (!numericRegex.test(keyValue)) {
      event.preventDefault();
    }
  }

  removeLeadingZeros(event: Event) {
    const input = event.target as HTMLInputElement;
    input.value = input.value.replace(/^0+/, '');
  }

  formatInput(event: Event) {
    const input = event.target as HTMLInputElement;
    const value = input.value.replace(/\D/g, '');
    const formattedValue = this.formatNumberSpacing(value);
    input.value = formattedValue;
    this.saveAmount(parseInt(value));
  }

  formatNumberSpacing(value: string) {
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }

  navigatePage(url: string) {
    this.router.navigate([url]);
  }

}


