<div class="container">
  <div class="row pt-7 pl-6 pr-7">
    <div class="col-12">
      <div class="d-flex mb-5">
        <ui-button [btnClass]="'round-secondary'" [roundBtn]="true" [iconName]="'interface-arrow-right-long'"
                   [size]="'sm'" [disabled]="false" (click)="navigateToEntranceHall()"></ui-button>
      </div>
      <div class="d-flex align-items-center">
        <h4 class="mb-0">Products</h4>
        <hr class="flex-grow-1 ml-3 my-0">
      </div>
      <div class="fixed-tabs d-flex align-items-center justify-content-between mt-3 mb-5" #elemNav>
        <ul ngbNav #nav="ngbNav" [activeId]="0" class="nav-tabs">
          <li *ngFor="let item of tabs" [ngbNavItem]="item.id" (click)="selectTab(item.name,item.id)">
            <a ngbNavLink>{{item.name}}</a>
          </li>
        </ul>
      </div>
      <div class="chat-icon">
        <ui-icon [name]="'help-and-support-live-chat'" [size]="'24'" [color]="'white'"
                 [animateStyle]="'animate-spin'" (click)="navigateToContactBanker()"></ui-icon>
      </div>
    </div>
  </div>
  <div class="row pl-6 pr-7">
    <div class="col-12">
      <input class="rounded-search mr-2 mb-2" type="text" [(ngModel)]="searchTerm" (input)="searchProducts()"
             placeholder="Search Products">
      <ui-button [btnClass]="'primary'" [block]="false" [disabled]="false" routerLink="/compare-products">
        Compare Products
      </ui-button>
    </div>
  </div>
  <div class="row pl-6 pr-7">
    <div class="col-12">
      <div class="mt-4 mb-3">
        <button *ngFor="let item of advancedFilter.filterSelection" type="button" class="mr-2 mb-2 btn btn-outline-secondary"
                (click)="selectAdvancedFilter(item.name,item.id)">{{item.name}}</button>
      </div>
    </div>
  </div>
  <div class="row  pl-6 pr-7 pb-7">
    <div class="col-12">
      <app-product-card [products]="filteredProducts" [showDescription]="true"></app-product-card>
      <ui-alerts *ngIf="filteredProducts.length <= 0"
        [message]="'There are no applicable products to display'"
        [alertName]="'info'"
      ></ui-alerts>
    </div>
  </div>
</div>

