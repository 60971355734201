import { BankingCard } from "@investec/io-bootstrap/cards/banking-card";
import { Attributes } from "./Attributes";

type InputType = "amount" | "multi-select" | "card-select"; 

type inputOption = {
    option: string
    attributes: Attributes
    cardOption?: BankingCard
}

export interface QuestionAnswer{
    option: string | number,
    attributes: Attributes
}

export type QuestionAnswers = QuestionAnswer[]

export interface Question{
    title?: string;
    text: string;
    subtext: string;
    skipText?: string;
    inputType: InputType
    inputOptions?: inputOption[];
}
export class QuestionClass implements Question{
    
    text: string;
    subtext: string;
    inputType: InputType; 
    inputOptions: inputOption[];

    constructor() {
        this.text = "";
        this.subtext = "";
        this.inputType = "amount";
        this.inputOptions = [];
    }
}