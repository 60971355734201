import {NgModule} from '@angular/core';
import {RouterModule, Routes} from "@angular/router";
import {HelpSelectProductComponent} from "../help-select-product/help-select-product.component";
import {ExploreProductsComponent} from "../explore-products/explore-products.component";
import {ContactBankerComponent} from "../contact-banker/contact-banker.component";
import {EntranceHallComponent} from "../entrance-hall.component";
import { CompareProductsComponent } from '../explore-products/compare-products/compare-products.component';
import { RecommendedProductsComponent } from '../help-select-product/recommended-products/recommended-products.component';


const routes: Routes = [
  {
    path: 'recommended-products',
    component: RecommendedProductsComponent
  },
  {
    path: 'help-select-product',
    component: HelpSelectProductComponent
  },
  {
    path: 'contact-banker',
    component: ContactBankerComponent
  },
  {
    path: 'explore-products',
    component: ExploreProductsComponent
  },
  {
    path: 'compare-products',
    component: CompareProductsComponent
  },
  {
    path: 'entrance-hall',
    component: EntranceHallComponent
  },
  {
    path: '',
    redirectTo: 'entrance-hall',
    pathMatch: 'full'
  }
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
