import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-contact-banker',
  templateUrl: './contact-banker.component.html',
  styleUrls: ['./contact-banker.component.scss']
})
export class ContactBankerComponent  implements  OnInit {

  clickedContactInvestecTeam = false;
  callBackTrigger = false;

  ngOnInit(): void {
    this.clickedContactInvestecTeam = true;
    this.callBackTrigger = true;
  }

}
