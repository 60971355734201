<div class="container">
  <div class="row pb-7">
    <div class=" recommended-products">
      <ui-button [btnClass]="'round-secondary'" [roundBtn]="true" [iconName]="'interface-arrow-right-long'"
                 [size]="'sm'"
                 [disabled]="false" (click)="navigateToEntranceHall()"></ui-button>
      <div class="row mb-3 pl-6 pr-7">
        <div class="col-md-7 col-lg-7 col-sm-12 recommended-products-heading">
          <div class="recommended-products-sai">SAVE & INVEST</div>
          <div class="display-3 recommended-products-title">Recommended for you</div>
          <div class="recommended-products-subtitle">Based on the information you have provided, the following savings &
            investment products could suit your
            needs.
          </div>
        </div>
        <div class="col-md-5 col-lg-5 col-sm-12 mb-5">
          <div class="d-flex justify-content-center">
            <img class="img-fluid"
                 src="https://www.investec.com/content/dam/investec-online/next/private-client-subsegment-family-save.jpg"
                 alt="Save & Invest Image"/>
          </div>
        </div>
      </div>
      <div class="row  pl-6 pr-7 pb-7">
        <div class="col-12 col-sm-12 col-md-6 col-lg-12 col-md-12">
          <div *ngIf="filteredProducts.length>0; else noProducts" class="col-12">
            <app-product-card [products]="filteredProducts" [showDescription]="false"></app-product-card>
          </div>
          <ng-template #noProducts>
            <div>
              <ui-alerts
                [message]="'There are no applicable recommended products to display'"
                [alertName]="'info'"
              ></ui-alerts>
              <p class="display-5 mt-6">Similar Products</p>
              <p class="display-6 mb-6">Here are a list of our other product offerings that may fit what you are looking
                for. </p>
              <app-product-card [products]="originalProducts" [showDescription]="true"></app-product-card>
            </div>
          </ng-template>
        </div>
      </div>

      <div class="buttons-container">
        <ui-button [customIcon]="true" [iconName]="'interface-resubmit'" [roundBtn]="false"
                   [disabled]="false" [theme]="'btn-transparent'"  [btnClass]="'primary'" (click)="navigateToPage('help-select-product')">Start
          again
        </ui-button>

        <ui-button [customIcon]="true" [roundBtn]="false" [btnClass]="'secondary'"
                   [disabled]="false" [theme]="'btn-transparent'" (click)="navigateToPage('compare-products')"> Compare
          Products
        </ui-button>

        <ui-button [customIcon]="true" [roundBtn]="false" [btnClass]="'secondary'"
                   [disabled]="false" [theme]="'btn-transparent'" (click)="navigateToPage('contact-banker')"> Speak to
          your Investec team
        </ui-button>

        <ui-button [btnClass]="'link'" [block]="false" [size]="'md'"
                   (click)="navigateToPage('explore-products')">
          Explore all products
        </ui-button>
      </div>

      <div class="chat-icon">
        <ui-icon [name]="'help-and-support-live-chat'" [size]="'24'" [color]="'white'" [animateStyle]="'animate-spin'"
                 (click)="navigateToPage('contact-banker')"></ui-icon>
      </div>
    </div>
  </div>
</div>
