import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { OneViewId } from '../models/one-view-id';
import { IClientDetails } from '../models/client-details.model';
import { invsy } from "@investec/invsy-sdk";
import { ReplaySubject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ForYouService {
  CONFIG = {
    BASE_URL: '/proxy',
  };
  homeLoanCallBackUrl = environment.forYou.homeLoan;
  oneViewIdBaseUrl = environment.shopfront.oneViewIdBaseUrl;
  stagingUrl = 'https://loginstg.secure.investec.com/wpaas/';
  _authResponse = new ReplaySubject<any>(1);
  _featureApps = new ReplaySubject<any>(1);
  _selectedProfile = new ReplaySubject<any>(1);
  _userSetting = new ReplaySubject<any>(1);
  _portfolio = new ReplaySubject<any>(1);

  constructor(private http: HttpClient) {
    invsy.data.getAllUserContext().then((res: any) => {
      this._authResponse.next(res.result.AuthResponse);
      this._featureApps.next(res.result.FeatureApps);
      this._selectedProfile.next(res.result.selectedProfile);
      this._userSetting.next(res.result.userSettings);
    })
    invsy.data.portfolio.getAllPortfolios('ZAR', undefined).subscribe(res => {
      this._portfolio.next(res.result);
    })
  }

  requestCallBack(payload: any) {
    return this.http.post(this.homeLoanCallBackUrl, payload);
  }

  getOneViewId() {
    return this.http.get<OneViewId>(this.oneViewIdBaseUrl + '/api/v1/oneviewid', {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  getClientDetails() {
    return this.http.get<IClientDetails>( '/proxy/za/pb/client/clientdetails');
  }

}
