import {Component, OnInit} from '@angular/core';
import {invsy} from "@investec/invsy-sdk";
import {NavigationEnd, Router} from "@angular/router";
import {NavigationService} from "./entrance-hall/services/navigation/navigation.service";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'SaveAndInvestWebApp';

  constructor(private router: Router,
              private _navigation: NavigationService) {

    this._navigation.setUrlOnRouteChange();
  }

  ngOnInit() {
    invsy.platform.init();
    invsy.platform.initSession();
  }
}
