import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppModuleStorybook } from '@investec/io-bootstrap';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgbModule, NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { ProductCardComponent } from './components/product-card/product-card.component';
@NgModule({
  declarations: [
    ProductCardComponent
  ],
  imports: [
    CommonModule,
    AppModuleStorybook,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    NgbCollapseModule,
    CdkStepperModule,
  ],
  exports: [
    AppModuleStorybook,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    NgbCollapseModule,
    ProductCardComponent,
  ],
})
export class SharedModule {}
