import { Component, OnInit } from '@angular/core';
import { BankingCard } from '@investec/io-bootstrap/cards/banking-card';
import { FeatureBanner } from '@investec/io-bootstrap/cards/feature-banner';
import { Router } from '@angular/router';
import {invsy} from "@investec/invsy-sdk";

@Component({
  selector: 'app-entrance-hall',
  templateUrl: './entrance-hall.component.html',
  styleUrls: ['./entrance-hall.component.scss']
})
export class EntranceHallComponent implements OnInit {

  saveAndInvestJourneyCards: any = [];
  bankingCards: BankingCard[] = [
    {
      showNotificationIcon: false,
      notification: false,
      header: 'Learn More',
      description:
        'What is the difference between saving and investing?',
      type: 'Bank',
    },
    {
      showNotificationIcon: false,
      notification: false,
      header: 'Did you know?',
      description:
        "South Africa's saving rate is currently sitting at 0.5%",
      type: 'Finance',
    },
    {
      showNotificationIcon: false,
      notification: false,
      header: 'Did you know?',
      description:
        'Your emergency fund should be 5% of your annual income.',
      type: 'Save',
    },
  ];

  wealthCards: FeatureBanner[] = [{
    index: 0,
    title: 'WEALTH',
    heading: 'Do you have more than R10M to invest?',
    description: 'Partner with Investec’s team of investment experts to make smart choices and to preserve and grow your wealth.',
    subDescription: '',
    showImage: true,
    imageUrl: 'https://www.investec.com/content/dam/investec-online/one-place/investment.jpg',
    primaryButton: {
      link_text: 'Learn More',
      link_url: 'https://stg.investec.com/next/segment/individuals',
    }}];

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.saveAndInvestJourneyCards = [
      {
        iconColor: 'amber3',
        iconName: 'interface-indicator-help',
        heading: 'Help me select a product',
        url:'help-select-product'
      },
      {
        iconColor: 'teal',
        iconName: 'miscellaneous-product-card',
        heading: 'Explore our products',
        url:'explore-products'
      },
      {
        iconColor: 'aubergine',
        iconName: 'interface-indicator-info',
        heading: 'I need more information',
        url:''
      },
      {
        iconColor: 'amber3',
        iconName: 'help-and-support-call-talking',
        heading: 'Speak to my Investec team',
        url:'contact-banker'
      }
    ]
  }
  navigatePage(url: string) {
    this.router.navigate([url]);
  }

  wealthButton(card: FeatureBanner){
    invsy.platform.navigateUrl('https://stg.investec.com/next/segment/individuals');
  }
}
