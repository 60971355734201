import {Component, Output} from '@angular/core';
import { Router } from '@angular/router';
import { Product } from '../../models/Product.interface';
import { ProductsService } from '../../services/products/products.service';
import { FilterService } from '../../services/filter/filter.service';
import { QuestionAnswers } from '../../models/Question.interface';

@Component({
  selector: 'app-recommended-products',
  templateUrl: './recommended-products.component.html',
  styleUrls: ['./recommended-products.component.scss']
})
export class RecommendedProductsComponent {

  public filteredProducts: Product[] = [];
  public originalProducts: Product[] = [];
  public questionAnswers: QuestionAnswers = [];


  constructor(private router: Router,
    public filterService: FilterService,
    public productService: ProductsService) {
    this.productService.getProducts().subscribe(products => {
      this.filteredProducts = this.filterService.getFilteredProducts(products);
      this.originalProducts = products;
    })
  }

  navigateToEntranceHall() {
    this.router.navigate(['entrance-hall']);
  }

  navigateToPage(url: string) {
    this.router.navigate([url])
  }

}
