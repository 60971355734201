import {Input, Component, OnInit, AfterViewInit, OnChanges, Output, EventEmitter} from '@angular/core';
import {Product} from "../../../models/Product.interface";
import {FeatureBanner} from "@investec/io-bootstrap/cards/feature-banner";
import {invsy} from "@investec/invsy-sdk";

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent implements OnChanges{

  @Input() products = [] as Product[]

  @Input() showDescription = true;
  badgesArray: any[] = []

  ngOnChanges(): void {
   this.badgesArray = this.products.map(item => {
     const newItem = {...item};
     (newItem.badges!.secondaryBadges as any) = (item.badges?.secondaryBadges && typeof item.badges.secondaryBadges === 'object') ? Object.values(item.badges?.secondaryBadges) : [];
     (newItem.badges!.tertiaryBadges as any) = (item.badges?.tertiaryBadges && typeof item.badges.tertiaryBadges === 'object') ? Object.values(item.badges?.tertiaryBadges) : [];
     return newItem
   })
    this.badgesArray.forEach((product) => {
      product.badges.secondaryBadges = product.badges?.secondaryBadges.filter( (badgeItem: string) => badgeItem.length > 0 )
      product.badges.tertiaryBadges = product.badges?.tertiaryBadges.filter( (badgeItem: string) => badgeItem.length > 0 )
    })
  }

  navigateToProductMoreDetails(url: string){
    invsy.platform.interactions.update({modalApp:{src:url,show:true}})
  }

  navigateToProductOnboarding(url: string){
    invsy.platform.navigateUrl(url);
  }
}
