<div class="container">
  <div class="row pb-7 pt-7 pl-6 pr-7">
    <div class="col-md-6 col-lg-6 col-sm-12">
      <p class="mt-5 display-3" id="projectTitle">Save & Invest</p>
      <p class="large"> When it comes to saving and investing, everyone has different needs. We offer a range of
        products to help you
        meet your savings and investment goals. Preserve and grow your wealth with a trusted, expert investment partner.
        Select your preferred option below to get started.</p>
    </div>
    <div class="col-md-6 col-lg-6 col-sm-12 ">
      <div class="d-flex justify-content-center">
        <img class="img-fluid" src="https://www.investec.com/content/dam/investec-online/next/for-you-privatevault.jpg"
             alt="Save & Invest Image"/>
      </div>
    </div>
  </div>

  <div class="row pr-7 pl-7 mt-2 mb-2">
    <div class="col-lg-6 col-md-6 col-sm-12 mb-2" *ngFor="let item of saveAndInvestJourneyCards; let i = index">
      <ui-rmb class="icon icon-color"
              [fullWidth]="true"
              [iconColor]="item.iconColor"
              [CTAIcon]="true"
              [RMBSize]="'large'"
              [iconName]="item.iconName"
              [heading]="item.heading"
              [background]="false"
              (click)="navigatePage(item.url)">
      </ui-rmb>
    </div>
  </div>

  <div class="row pl-6 pr-7">
    <div class="col-12">
      <div class="d-flex align-items-center mb-4">
        <hr class="flex-grow-1 ml-1 my-0">
      </div>
      <ui-cards-beyond-banking [cards]="bankingCards">
      </ui-cards-beyond-banking>
    </div>
  </div>

  <div class="row pl-6 pr-7">
    <div class="col-12">
      <div class="pt-4">
        <ui-cards-feature-banner [banners]="wealthCards" (rightButton)="wealthButton($event)"></ui-cards-feature-banner>
      </div>
    </div>
  </div>
</div>





