import {Component} from '@angular/core';
import {Router} from "@angular/router";
import {ProductsService} from "../../services/products/products.service";
import {Product} from "../../models/Product.interface";


@Component({
    selector: 'app-compare-products',
    templateUrl: './compare-products.component.html',
    styleUrls: ['./compare-products.component.scss']
})
export class CompareProductsComponent{

  products: Product[] = [];
  selectedProduct: Product[] = [];
  numberOfProducts: number = 0;
  selectedOptions: Product[] = []
  arrayNumber: number = 1;
    tabs = [
        {
            name: 'Product features',
            id: 0,
            content: 'Product features tab works!'
        }
    ]

  arrayIndex: number[] = [1,2,3]

  rowLabels = ['Interest Rate', 'Risk', 'Investment Term', 'Minimum Investment Amount', 'Monthly Deposit', 'Notice Period', 'Minimum Balance', 'Fees (Total Investment Charge)', 'Product Type']

  constructor(private router: Router,
              public _productService: ProductsService) {
    _productService.getProducts().subscribe(products => {
      this.initialiseProducts(products);
    });
  }

  initialiseProducts(products: Product[]) {
    this.products = products;
    this.numberOfProducts = this.products.length;
  }

    navigatePage(url: string) {
        this.router.navigate([url]);
    }

    activeTabIndex = 0;

    setActiveTab(index: number) {
        this.activeTabIndex = index;
    }

    tableOption(event: any, number: number){
      if(this.selectedProduct.length < 3 ){
        if(!this.selectedProduct.includes(event)){
          if(this.selectedProduct.length !== number){
            this.selectedProduct.splice(number,1, event)
          } else {
            this.selectedProduct.push(event);
          }
        }
      } else {
        this.selectedProduct.splice(number,1, event)
      }
    }

}
