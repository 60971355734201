<div class="container">
  <div class="row pb-7 pt-7 pl-6 pr-7">
    <div class="col-12">
      <div class="d-flex mb-5">
        <ui-button [btnClass]="'round-secondary'" [roundBtn]="true" [iconName]="'interface-arrow-right-long'"
                   [size]="'sm'" [disabled]="false" (click)="navigatePage('explore-products')"></ui-button>
      </div>
      <div>
        <p class="overline-2">Save & Invest</p>
      </div>
      <div class="d-flex align-items-center">
        <p class="display-5 mb-0">Compare products</p>
      </div>
      <div class="fixed-tabs d-flex align-items-center justify-content-between mt-2 mb-5" #elemNav>
        <ul ngbNav #nav="ngbNav" [activeId]="0" class="nav-tabs">
          <li *ngFor="let item of tabs; let i = index" [ngbNavItem]="item.id" (click)="setActiveTab(i)">
            <a ngbNavLink>{{item.name}}</a>
          </li>
        </ul>
      </div>

      <div class="card p-4">
        <div class="card-content">
          <div class="card-header pb-3 pt-1">
            <div class="row">
              <div class="col-4" *ngFor="let number of arrayIndex; let i = index">
                <ui-dropdown  [id]="'compareProducts'"
                             [fullWidth]="true"
                             [optionList]="products"
                             [disabled]="false"
                             [scrollBar]="false"
                             [isLabelBold]="true"
                             [selectedOption]="'Product ' + number"
                             [displayProperty]="'listedName'"
                             (returnEvt)="tableOption($event, i)"></ui-dropdown>
              </div>
            </div>
          </div>
          <div class="card-body pb-0 pr-0 pl-0">
            <div class="table-responsive">
              <table class="table">
                <thead>
                <tr>
                  <th> Selected Products</th>
                  <ng-container *ngFor="let product of selectedProduct">
                    <th>{{product.listedName}}</th>
                  </ng-container>
                </tr>
                </thead>

                <tbody class="col-3 col-lg-3 col-md-3">
                <tr>
                  <th>Interest Rate</th>
                  <ng-container *ngFor="let product of selectedProduct ">
                    <td>{{product.badges?.secondaryBadges?.interestRateInvest ? product.badges?.secondaryBadges?.interestRateInvest : product.badges?.tertiaryBadges?.interestRateSavings ? product.badges?.tertiaryBadges?.interestRateSavings : 'N/A'  }}</td>
                  </ng-container>
                </tr>
                <tr>
                  <th>Risk</th>
                  <ng-container *ngFor="let product of selectedProduct ">
                    <td *ngFor="let item of product.attributes">{{item['risk'] ? item['risk'] : 'N/A' }}</td>
                  </ng-container>
                </tr>
                <tr>
                  <th>Investment Term</th>
                  <ng-container *ngFor="let product of selectedProduct ">
                    <td>{{product.badges?.secondaryBadges?.term !== '' ? product.badges?.secondaryBadges?.term : 'N/A' }}</td>
                  </ng-container>
                </tr>
                <tr>
                  <th>Notice Period</th>
                  <ng-container *ngFor="let product of selectedProduct ">
                    <td>{{product.badges?.secondaryBadges?.accessibility !== '' ? product.badges?.secondaryBadges?.accessibility : 'N/A'}}</td>
                  </ng-container>
                </tr>
                <tr>
                  <th>Product Type</th>
                  <ng-container *ngFor="let product of selectedProduct ">
                    <td>{{product.badges?.primaryBadges?.isInvest ? 'Invest' : 'Save'}}</td>
                  </ng-container>
                </tr>
                <tr>
                  <th>Certainty</th>
                  <ng-container *ngFor="let product of selectedProduct ">
                    <td>{{product.badges?.secondaryBadges?.certainty !== '' ? product.badges?.secondaryBadges?.certainty : 'N/A'}}</td>
                  </ng-container>
                </tr>
                <tr>
                  <th>Offshore/Local</th>
                  <ng-container *ngFor="let product of selectedProduct ">
                    <td>{{product.badges?.tertiaryBadges?.typeOfUnitTrust !== '' ? product.badges?.tertiaryBadges?.typeOfUnitTrust : 'N/A'}}</td>
                  </ng-container>
                </tr>
                <tr>
                  <th>Accessibility</th>
                  <ng-container *ngFor="let product of selectedProduct ">
                    <td>{{product.badges?.secondaryBadges?.accessibility !== '' ? product.badges?.secondaryBadges?.accessibility : 'N/A'}}</td>
                  </ng-container>
                </tr>
                <tr>
                  <th>Notice Period</th>
                  <ng-container *ngFor="let product of selectedProduct ">
                    <td>{{product.badges?.secondaryBadges?.noticePeriod !== '' ? product.badges?.secondaryBadges?.noticePeriod : 'N/A'}}</td>
                  </ng-container>
                </tr>
                <tr>
                  <th>Tax Free</th>
                  <ng-container *ngFor="let product of selectedProduct ">
                    <td>{{product.productType?.includes('Tax Free Investments') ? 'Yes' : 'No'}}</td>
                  </ng-container>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
