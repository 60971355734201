<div class="container">
  <div class="row pb-7 pt-7 pl-6 pr-7">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
<!--      <div class="d-flex mb-5">-->
<!--        <ui-button [btnClass]="'round-secondary'" [roundBtn]="true" [iconName]="'interface-arrow-right-long'"-->
<!--                   [size]="'sm'" [disabled]="false" (click)="navigatePage('entrance-hall')"></ui-button>-->
<!--      </div>-->
      <div class="card p-4">

        <div *ngIf="numberOfQuestions > 0" class="card-body">
          <div class="d-flex align-items-center">
            <div class="step-text">
              STEP {{currentQuestionNumber + 1}} OF {{numberOfQuestions}}
            </div>
            <div class="exit-button ml-auto">
              <ui-button [btnClass]="'close'"
                         [size]="'sm'" [disabled]="false" (click)="openExitModal()"></ui-button>
            </div>

            <div class="chat-icon">
              <ui-icon [name]="'help-and-support-live-chat'" [size]="'24'" [color]="'white'"
                       [animateStyle]="'animate-spin'" (click)="navigateToContactBanker()"></ui-icon>
            </div>
          </div>

          <div *ngIf="currentQuestion.inputType=='multi-select'">
            <div class="multi-select-text">
              {{currentQuestion.text}}
            </div>
            <div class="multi-select-subtext">
              {{currentQuestion.subtext}}
            </div>
            <div *ngIf="currentQuestion.title" class="multi-select-title">
              {{currentQuestion.title}}
            </div>
            <div class="input-options pt-2">
              <div *ngFor="let option of optionList" class="input-option p-1">
                <ui-chips *ngIf="option.isSelected" [label]="option.option" [isSelected]="true"
                          [icon]="'ticks-small'" position="left" (click)="clickOption(option.option)"></ui-chips>
                <ui-chips *ngIf="!option.isSelected" [label]="option.option" [isSelected]="false"
                          (click)="clickOption(option.option)"></ui-chips>
              </div>
            </div>

            <hr class="divider">
            <div class="button-group">
              <ui-button class="button-back" btnClass="secondary" (click)="clickBack()">Back</ui-button>
              <ui-button class="button-continue" btnClass="primary" (click)="clickContinue()"
                         [disabled]="selectedOption == ''">Continue
              </ui-button>
            </div>

          </div>
          <div *ngIf="currentQuestion.inputType=='amount'">
            <div class="amount-text">
              {{currentQuestion.text}}
            </div>
            <div class="amount-subtext">
              {{currentQuestion.subtext}}
            </div>
            <div *ngIf="currentQuestion.title" class="display-3 amount-title">
              {{currentQuestion.title}}
            </div>
            <div class="amount-input">
              <span class="currency">R</span>
              <input type="text" placeholder="0" [ngModel]="inputAmount" (keypress)="preventNonNumericInput($event)"
                     (input)="formatInput($event)" class="input-field">
              <hr class="amount-divider">
            </div>

            <hr class="divider">
            <div class="button-group">
              <ui-button class="button-back" btnClass="secondary" (click)="clickBack()">Back</ui-button>
              <ui-button class="button-continue" btnClass="primary" (click)="clickContinue()">Continue</ui-button>
            </div>
          </div>
          <div *ngIf="currentQuestion.inputType=='card-select'">
            <div class="card-select">
              <div class="d-flex align-items-center">
                <div class="card-select-title">
                  {{currentQuestion.title}}
                </div>
                <hr class="flex-grow-1 ml-3 my-0">
              </div>
              <div>
                <div class="card-select-cards">
                  <ui-cards-beyond-banking [cards]="cards" (clickedCard)="clickCard($event)">
                  </ui-cards-beyond-banking>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #modalExit let-modalExitX>
  <div class="modal-header">
    <h4 class="mb-0">Unsaved answers</h4>
  </div>
  <div class="modal-body">
    <p class="mb-0">Your answers have not been saved. If you leave now, all answers will be lost. Are you certain you want to discard these answers?</p>
  </div>
  <div class="modal-footer d-flex justify-content-end">
    <ui-button [btnClass]="'secondary'" [block]="true" (click)="modalExitX.close()">Cancel</ui-button>
    <ui-button [btnClass]="'secondary'" [block]="true" (click)="closeModal();navigatePage('entrance-hall')">Discard answers</ui-button>
  </div>
</ng-template>
