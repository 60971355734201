// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  activeEnv: 'local',
  production: false,
  development: true,
  ServiceWorker: false,
  apiBaseUrl: 'https://10.0.149.146:3000/account-list/1.0.0',
  dashboard: {
    WiZaPortfolioV2: true,
    MuCorporateProfiles: true,
    PaymentHoliday: true,
    showManageLimitsLink: false,
    muShowDashboardMessage: true,
  },
  platform: {
    callsign: true,
    callsignEtgCall: true,
    callsignConfig: {
      essx: 'https://app.s06.callsign.com',
      esto: 'org-k49w1p01-xjegnbes-ba8bgm19d-kqsv0cka',
      apiKey: 'g3GBDUxq61VF5sQY2krOuuxR4JHF34LN7qlqeJmFq2zE2q6KdwtxLWbHDqYjBEjr',
      tenantId: 'tnt-7n6pn4th-ejn107r3-9armvmvcn-84b2fmhs',
    },
    showMarketplaceMenu: true,
    enableBrowserDetect: true,
    ixContactNumbers: true,
    liveChatToggleCategory: 'API.LiveChat',
    platformAuthorCategory: 'PlatformAuthor',
    platformAuthenticationCategroy: 'PlatformAuthentication',
    platformAuthorBaseUrl: '/proxy/user/v2',
    home: true,
    authentication: true,
    secondFactor: true,
    logout: true,
    userApi: true,
    userFeatures: true,
    errorToast: true,
    liveChatEnabled: true,
    megaMenuEnabled: true,
    liveChatUrl: 'https://apidev.secure.investec.com/livechat',
    liveChatSkillset: 'WC_Default_Skillset',
    urls: {
      ixDestination: 'https://ix-dev.secure.investec.com/',
      ifaUkDestination: '/ifaroot/uk-ifa-dashboards/investec-for-advisers',
      sociusDestination: '',
      envBaseUrl: 'https://logindev.secure.investec.com',
      ioPcoBaseUrl: 'https://applyonlinedev.investec.com',
    },
    stickyFooterEnvironmentToggle: true,
    disableUpdateMyDetailsSa: true,
    wealthTempPassBaseUrl: '/az-proxy/iwi-uk-apidev.secure.investec.com',
  },
  wealthSA: {
    toggles: 'UXP.ZA.WI',
  },
  myInvestmentSA: {
    toggles: 'UXP.ZA.Investments',
  },
  hybrid: {
    toggles: 'Hybrid',
  },
  bb: {
    fbccUrl: 'https://fbccstg.secure.investec.com',
    apiBaseUrl: '/proxy/za/tbb/api',
    mockBaseUrl: 'https://10.0.149.39:3000',
    toggles: 'API.ZA.TBB',
    FBCC_DayOld_Support_Enabled: true,
  },
  ifa: {
    // API base url
    ccmBaseURL: 'https://cxtifi-nonprod.secure.investec.com/api/v1',
    // UI url
    ccmUIBaseUrl: 'https://loginstg.secure.investec.com',
    ccmUIProxyUrl: '/proxy',
    toggles: 'API.ZA.IB',
    enableCCMClientManagement: '02bb526eb37d9455f76db2e279ae15d3b6cb692e2a0142af88652176cc94158e',
    enableCCMIntermediaryAdmin: '88aa3361096e20f30cc40b2f3afb703f968447e9d6dd6100c94fd14632304594',
    enableCCMRatesAndFees: '7473984db0d522a525ab09f40a3644af0c0db51dd27f04d205b7fd43684b13c8',
    enableCCMDocuments: '39f0e10c89771ed1ac205112cc960d94a6b3035a31696c805a3b252f05df2fd4',
    enableCCMGuarantee: 'ed9c92f7afbfba13b0668b3bc515d9685884e56eac70d97ebaf17b11bf77f38d',
    enableCCMClientAdmin: '705a6b4d289cab5cf6d2d1948040c8f5576f0f927f2e7729b409a01f895df609',
    enableCCMBulkClosure: '4993fa117f211beb83919565bb2ef6c6c3221287f5cad514aeef82073f82bd23',
    enableMAUOffshoreBanking: 'd7c498a81e56abf7185dbe1aea40f2d63e0edde30c34b553c20f477f574176b8',
    enableCCMMessagingCenter: 'aa22ef32fefbd41d090fd305e4a81ef3ba7bb430bc2b5d412ac0e46611ccb24b',
    enableCCMClientOnboarding: '8773f87b49f6874ca1b4b8c7e1c85c7fe58823ca9946ddd431a81943269bb845'
  },
  bbuk: {
    ecpService: 'https://afd-lcgrcp-prod-001.azurefd.net',
    dotComContentBaseUrl: 'https://www.investec.com',
    elkService: 'https://elk.investec.com',
  },
  paymentsSA: {
    preselectedAccountAndProfile: true,
    csvInjection: false,
    userLevel0Changes: false,
    manageBenGeoLinkToUK: false,
    authorisationFasterPaymentAsCurrentDay: false,
    querySelectorRemoval: false,
    spotScamSnatching: false,
    dashboardExtract: true,
    rewardsV2: true,
    multiplePaymentConfIssueFixes: false,
    shouldShowLinkUKAccounts: false,
    removeFaxOption: false,
    elecTariffsDoc: true,
    auditTrail: false,
    auditTrailAuthorisedBy: false,
  },
  pcuk: {
    cdxPcUkService: 'https://cdx-pc-apidev.secure.investec.com',
  },
  pbuk: {
    ukExecClient: 'https://ctr-lcg-ecp-nonprod.secure.investec.com/contactdetails',
    apiMapping: '/bin/restproxy',
    toggles: 'UXP.UK.BANKING',
    noticePlus: {
      AllowCloseAccountOnly: true,
    },
  },
  wiukci: {
    cdxPcWiukciBaseUrl: 'cdx-pc-apidev.secure.investec.com/',
  },
  pbmu: {
    makeAPayment: true,
    toggles: {
      redirectToOldOnlineBanking: {
        makeAPayment: {
          beneficiary: {
            enabled: false,
            path: '/pbmu/pbmu.html/beneficiaryPayment',
          },
          onceOff: {
            enabled: false,
            path: '/pbmu/pbmu.html/onceoffpayment',
          },
          transfer: {
            enabled: false,
            path: '/pbmu/pbmu.html/transfers',
          },
          scheduledPayment: {
            enabled: false,
            path: '/pbmu/pbmu.html/addStandingOrder',
          },
          trade: {
            enabled: false,
            path: '/pbmu/pbmu.html/forex',
          },
        },
        manageBeneficiaries: {
          enabled: false,
          path: '/pbmu/pbmu.html/manageBeneficiaries',
        },
      },
      crossCurrencyEnhancements: true,
      accountTransferEnhancements: false,
      aggregatedAccountList: true,
      showCategories: true,
      bankingAccountNewRequestInput: true,
      zeroBalanceSolution: true,
      IsPurposeOfPaymentRequired: true,
      scheduledPaymentFixToggle: true,
      mauritiusValidateIntAccToggle: false,
      validateFedwireSortCodeToggle: true,
      isCCMLiveToggle: true,
      fixedDepositEnhancementsToggle: true
    },
  },
  pcoExistingClients: {
    baseUrl: 'https://logindev.secure.investec.com',
    pcoToggleCategory: 'Online.PCO',
    reId: true,
  },
  myInvestments: {
    isWebView: false,
    endpoints: {
      fundingAccountsUrl: 'investments/investment/fundingaccounts',
      wiInitiateFundingUrl: 'wi/portfolio/InitiateFunding',
      pbInitiateFundingUrl: 'pb/portfolio/InitiateFunding',
      industrySectionsUrl: 'investments/compliance/IndustrySections',
      industryGroupsUrl: 'investments/compliance/IndustryGroups',
      industryDetailsUrl: 'investments/compliance/IndustryDetails',
      occupationGroupsUrl: 'investments/compliance/OccupationGroups',
      occupationUnitsUrl: 'investments/compliance/OccupationUnits',
      productsUrl: 'wi/products',
      lookupUrl: 'opm/reference/lookup',
      sourceOfWealth: '/investments/compliance/SourcesOfWealth',
      creatInstructionUrl: 'investments/investment/createinstruction',
      categoriesUrl: 'investments/Product/ProductShelf',
      fundsproductsUrl: 'wi/productcards',
    },
  },
  spf: {
    lightstone: {
      hasComparableSales: true,
      hasPointsOfInterest: true,
      hasPropertyDetails: true,
      hasUIAlerts: true,
      hasMarkerDrawingTool: true,
    },
    hasPaymentPlan: true,
    hasPropertySummary: true,
    hasExposuresSummary: true,
    hasDashboard: true,
    hasMapSummary: true,
  },
  rewards: {
    calculatorEnabled: false,
    documentsEnabled: false,
    expiryPointsV2: false,
    perPageDataEnabled: false,
    anchorTransformHelperEnabled: true,
  },
  pbza: {
    hasTemporaryLimitManipulation: false,
    routeToIoZA: false,
  },
  cards: {
    useV2CardApi: true,
    showPinLayoutAndLandingPage: true, // To show hide the Pin Layout
    changeCardPinEnabled: true,
    showLinkedPaymentDevices: true,
    showVirtualCards: true,
    showCardSettings: true,
    showCardLimits: true,
    useV2CardModule: true,
    showCancelAndReplace: true,
    pageSize: 25,
  },
  beneficiary: {
    auditTrailDrop2: false,
  },
  privateClientsDashboard: {
    multipleManagers: true,
    preferenceAPI: 'cxt-dashboardstg.secure.investec.com',
    toggles: 'Dashboard',
  },
  ifb: {
    UseAPIV2: true, // to handle fbcc calls when fbcc is done
    googleKey: 'AIzaSyBIhXTZU0PoWAqY6MCVTNIvhJXiaydgCPE',
    apiBaseUrl: '/proxy/za/tbb/api',
    enableProfileSwitcher: true,
  },
  wici: {
    valuationSelectionMinimumDate: { year: 2018, month: 6, day: 28 }, // Used for restricting documents before a certain date being selected in documents
    taxpackSelectionMinimumDate: { year: 2018, month: 1, day: 1 }, // Used for restricting documents before a certain date being selected in documents
    lossNotificationSelectionMinimumDate: { year: 2020, month: 6, day: 11 }, // Used for restricting documents before a certain date being selected in documents
    feeInvoiceSelectionMinimumDate: { year: 1960, month: 1, day: 1 }, // Used for restricting documents before a certain date being selected in documents
  },
  wiuk: {
    valuationSelectionMinimumDate: { year: 2018, month: 6, day: 28 }, // Used for restricting documents before a certain date being selected in documents
    taxpackSelectionMinimumDate: { year: 2018, month: 1, day: 1 }, // Used for restricting documents before a certain date being selected in documents
    lossNotificationSelectionMinimumDate: { year: 2020, month: 6, day: 11 }, // Used for restricting documents before a certain date being selected in documents
    feeInvoiceSelectionMinimumDate: { year: 1960, month: 1, day: 1 }, // Used for restricting documents before a certain date being selected in documents
  },
  shopfront: {
    baseUrl: '/aem-api/',
    omniChannel: {
      data_app_id: '9e442c23-3585-4b52-a3ab-fbb4bef4c376',
      data_lcw_version: 'prod',
      data_org_id: '1cc5046c-d3dd-4d05-8efd-5815dd872517',
      data_org_url: 'https://org65633939-crm4.omnichannelengagementhub.com',
      enableChat: true,
    },
    toggles: 'UXP.Shopfront',
    apiUrl: '/proxy/',
    requestcallBackUrl: 'https://apidev.secure.investec.com/dotcom/lead/v2/submit',
    insightsEngineBaseUrl: 'https://cxt-shopfrontstg.secure.investec.com/',
    mainNavBaseUrl: 'https://www.investec.com/',
    oneViewIdBaseUrl: 'https://cxt-shopfrontstg.secure.investec.com',
    dataInsertionUrl: 'https://smetrics.investec.com/b/ss/invbnkplaygroundstaging/1/JS-2.22.4-LDQM',
  },
  connect: {
    apiBaseUrl: 'https://cxt-iconnectstg.secure.investec.com/',
    messagingBaseUrl: 'https://chirpeee.com/',
    eventsEnabled: false,
    messagingEnabled: false,
  },
  forYou: {
    insightsCardAPI: 'https://cxt-personalisationstg.secure.investec.com',
    aemBaseUrl: '/ct-proxy/',
    toggles: 'UXP.ForYou',
    apiUrl: '/proxy/',
    homeLoan: 'https://apidev.secure.investec.com/dotcom/lead/v2/submit',
    dataInsertionBaseUrl: 'https://smetrics.investec.com/b/ss/invbnkplaygroundstaging/1/JS-2.22.4-LDQM',
    personalizationCode: {
      env: 'NonProd',
      garmin: {
        activityName: 'garminCodeDisplay',
        toggle: '11278b7fa8d968cbbe1330aba6cf3b4cd301277eb6704c1a4904ffa163fe44d3',
      },
      homeLoan: {
        activityName: 'homeLoanInsight',
        toggle: 'd1faeacf822bb6ebddd21a0f33e0f3d877ea35b7ad20ff3834cf3af5113fd768',
      },
      privateLane: {
        activityName: 'privateLane',
        toggle: '3e76ee84931328da5db28248895ff5b35409d6b9f976f5669764bcdad3e90629',
      },
      connect: {
        activityName: '',
        toggle: '1a2303ede07493acc7caaa7c737f3c52bcc9cf04372be19ed1b0af6b9f2c791e',
      },
      privateVault: {
        activityName: '',
        toggle: 'a7987e4efb5fa6d7d96c9e3051302e4aff33481d95aeb435ab211de667f3e8ce',
      },
      beyondBanking: {
        activityName: '',
        toggle: '357033683861d2333034d186581ba291c0d1125c7890f1c0d375fdaea9a0232d',
      },
      travel: {
        activityName: '',
        toggle: 'd2b98fb53714f23cc0aa7b0ad6dbf910dd537237d7debe818a142590a322b992',
      },
      babylonstoren: {
        activityName: 'babylonstorenCode',
        toggle: '158f75f3b0a8bf8761ff791335a8de1505a17c7111ff740eb3abccc52faeb9ae',
      },
    },
  },
  musu: {
    onlineBankingProfile: 'TMOB_STG',
  },
  privateVault: {
    apiUrl: '/az-proxy/cxt-data-vault-nonprod.secure.investec.com',
    alertTimeout: 3000,
  },
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
