import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import {AppComponent } from './app.component';
import {AppModuleStorybook} from "@investec/io-bootstrap";
import {NgbActiveModal, NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {EntranceHallComponent } from './entrance-hall/entrance-hall.component';
import { HelpSelectProductComponent } from './entrance-hall/help-select-product/help-select-product.component';
import { ExploreProductsComponent } from './entrance-hall/explore-products/explore-products.component';
import { ContactBankerComponent } from './entrance-hall/contact-banker/contact-banker.component';
import {RouterOutlet} from "@angular/router";
import {AppRoutingModule} from "./entrance-hall/app-routing/app-routing.module";
import {
  PrivateBankerRequestCallbackComponent
} from "./entrance-hall/contact-banker/private-banker-request-callback/private-banker-request-callback.component";
import { HttpClientModule} from "@angular/common/http";
import { QuestionsService } from './entrance-hall/services/questions/questions.service';
import { ProductsService } from './entrance-hall/services/products/products.service';
import {SharedModule} from "./entrance-hall/shared/shared.module";
import { CompareProductsComponent } from './entrance-hall/explore-products/compare-products/compare-products.component';
import {FormsModule} from "@angular/forms";
import { RecommendedProductsComponent } from './entrance-hall/help-select-product/recommended-products/recommended-products.component';

@NgModule({
  declarations: [
    AppComponent,
    EntranceHallComponent,
    HelpSelectProductComponent,
    ExploreProductsComponent,
    CompareProductsComponent,
    ContactBankerComponent,
    PrivateBankerRequestCallbackComponent,
    RecommendedProductsComponent
    ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AppModuleStorybook,
    NgbModule,
    RouterOutlet,
    HttpClientModule,
    SharedModule,
    FormsModule
  ],
  providers: [
    NgbActiveModal,
    QuestionsService,
    ProductsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
