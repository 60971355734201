import { Injectable } from '@angular/core';
import { Product } from '../../models/Product.interface';
import { ProductsService } from '../products/products.service';
import { Attribute, Attributes } from '../../models/Attributes';
import { QuestionAnswer, QuestionAnswers} from '../../models/Question.interface'

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  private products: Product[] = [];
  public questionAnswers: QuestionAnswers = [];
  constructor(public productsService: ProductsService) {
    this.productsService.getProducts().subscribe(products => {
      this.products = products;
    });
  }
  public attributes: Attributes = [];

  setQuestionAnswers(questionAnswers: QuestionAnswers) {
    this.questionAnswers = questionAnswers;
  }

  compileAttributes(questionAnswers: QuestionAnswers){
    var result: Attribute = {};
    questionAnswers.forEach(answer => {
      answer.attributes.forEach(attribute =>{
        Object.entries(attribute).forEach(([key, value])=>{
          if(result[key]) {
            result[key].push(...value);
          } else {
            result[key] = value;
          }
        })
      })
    });
    var attributes = [];
    attributes.push(result);
    return attributes;
  }

  getAttributeKeyList(attributes: Attributes){
    return Object.keys(attributes[0]);
  }

  getProductAttributeKeyList(product: Product){
    return this.getAttributeKeyList(product.attributes);
  }

  productMatchesAttributes(product: Product, attributes: Attributes){
    const attributeKeys = this.getAttributeKeyList(attributes);
    const productAttributeKeys = this.getProductAttributeKeyList(product);
    var flag = true;
    attributeKeys.forEach(attributeKey => {
      if(productAttributeKeys.includes(attributeKey)){
        const filterValues = attributes[0][attributeKey];
        const productValues = product.attributes[0][attributeKey]
        filterValues.forEach(filterValue => {
          if(!productValues.includes(filterValue)){
            flag = false;
          }
        });
      }
    });
    return flag;
  }

  getFilteredProducts(products: Product[]) {
    console.log("getFilteredProducts");
    var result: Product[] = [];
    const filterAttributes = this.compileAttributes(this.questionAnswers);
    console.log("Filter",filterAttributes);
    console.log("Products:",products);
    products.forEach(product =>{
      console.log("Product:", product.attributes);
      console.log("Filter",filterAttributes);
      if(this.productMatchesAttributes(product,filterAttributes)){
        result.push(product);
      }
    });
    return result;
  }

}
