<ng-template #modal *ngIf="!isCallBack">
  <div class="modal-header">
    <p class="display-4">Request a callback from your Private Banker</p>
  </div>
  <div class="modal-body pt-0">
    <p class="mt-1">Your Private Banker is here to help tailor a solution for you.</p>
    <div class="mt-4 d-md-flex justify-content-between" *ngIf="!isloading && !isFailed">
      <div class="mb-2">
        <h4 class="mb-0">{{bankerDetails.DisplayName}}</h4>
        <p class="mb-0 medium">Private Banker</p>
      </div>
      <div class="text-md-left">
        <div class="d-flex"><a class="medium text-decoration-none"
                               href="tel:{{bankerDetails.MobileNumber}}"> {{bankerDetails.MobileNumber}} </a><span
          class="mx-1 divider">|</span><a class="medium text-decoration-none"
                                          href="tel:{{bankerDetails.PhoneNumber}}"> {{bankerDetails.PhoneNumber}} </a>
        </div>
        <div class="email"><a href="mailto:{{bankerDetails.Email}}">{{bankerDetails.Email}}</a>
        </div>
      </div>
    </div>
    <ui-bullet-list-content-loader *ngIf="isloading" [customHeight]="false"></ui-bullet-list-content-loader>
    <div class="mt-4 d-md-flex justify-content-between" *ngIf="isFailed">
      <p>Your banker details have failed to load. Please refresh and try again. </p>
    </div>
  </div>
  <div class="modal-footer">
    <ui-button [btnClass]="'tertiary'" [block]="true" (click)="returnToEntranceHall()">Cancel</ui-button>
    <ui-button *ngIf="!isFailed && !isLesLoading" [btnClass]="'primary'" [block]="true" (click)="callBack()">Call me
      back
    </ui-button>
    <ui-button *ngIf="isFailed && !isLesLoading" [btnClass]="'primary'" [block]="true" (click)="refreshed()">Refresh
    </ui-button>
    <ui-button [btnClass]="'primary'" [loading]="isLesLoading" *ngIf="isLesLoading"></ui-button>
  </div>
</ng-template>
<ng-template #modalSuccess>
  <div class="modal-header justify-content-end">
    <button type="button" class="close" aria-label="Close" (click)="returnToEntranceHall()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body pt-0">
    <div class="modal-body-container">
      <div class="left-container">
        <div class="left-div">
          <p class="display-4">Callback Successfully Sent</p>
          <p class="mt-2">Your Private Banker will contact you soon.</p>
        </div>
        <div class="left-div">
          <ui-button id="done" [btnClass]="'primary'" (click)="returnToEntranceHall();"> Done
          </ui-button>
        </div>
      </div>
      <div class="right-container">
        <div class="right-div">
          <img class="" src="assets/illustration-success.png" width="180px" height="180px"/>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #modalError>
  <div class="modal-header justify-content-end">
    <button type="button" class="close" aria-label="Close" (click)="returnToEntranceHall()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body-container mt-0 pl-2">
    <div class="left-container">
      <div class="left-div px-2">
        <h3 class="modal-title heading-font">Something went wrong</h3>
        <p class="mt-2">There was a problem submitting the callback request, please refresh and try again. </p>
      </div>
      <div class="left-div px-2">
        <ui-button id="tryAgain" class="p-0" [btnClass]="'primary'" (click)="refreshLes()"> Please try again
        </ui-button>

      </div>
      <div class="left-div">
        <ui-button id="close" [btnClass]="'link'" (click)="returnToEntranceHall();"> Close
        </ui-button>
      </div>
    </div>
    <div class="right-container">
      <div class="right-div">
        <img class="" src="assets/warning.png" width="180px" height="180px"/>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #modalFail>
  <div class="modal-header justify-content-end">
    <button type="button" class="close" aria-label="Close" (click)="returnToEntranceHall()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body-container pl-2">
    <div class="left-container">
      <div class="left-div px-2">
        <p class="display-4">Callback Failed</p>
        <p class="mt-2">Unfortunately we were unable to send a callback request.</p>
      </div>
      <div class="left-div px-2">
        <ui-button id="tryAgain" class="p-0" [btnClass]="'primary'" (click)="refreshLes()"> Please try again
        </ui-button>
      </div>
      <div class="left-div">
        <ui-button id="close" [btnClass]="'link'" (click)="returnToEntranceHall();"> Close
        </ui-button>
      </div>
    </div>
    <div class="right-container">
      <div class="right-div">
        <img class="" src="assets/warning.png" width="180px" height="180px"/>
      </div>
    </div>
  </div>
</ng-template>


